import React from "react";
import "./footer.scss";
import { Link, useLocation } from "react-router-dom";
interface NavItem {
  name: string;
  path: string;
}
function Footer() {
  const location = useLocation();
  const currentPath = location.pathname;
  const navItems: NavItem[] = [
    { name: "Home", path: "/" },
    { name: "Projects", path: "/projects" },
    { name: "About us", path: "/aboutUs" },
    { name: "Contact us", path: "/contactUs" },
  ];
  return (
    <div className="footer flex">
      <p>&copy; All rights reserved by Aoin Designtech</p>
      {/* <div className="line1 flex">
        <Link to="/" title="Home">
          <h4 className="logoLine">AOIN DESIGNTECH</h4>
        </Link>
        <ul className="navBar flex">
          {navItems.map((navItem) => {
            return (
              <li
                className={`navItem ${
                  currentPath === navItem.path ? "active" : ""
                }`}
              >
                <Link to={navItem.path} title={navItem.name}>
                  {navItem.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </div> */}
      {/* <p className="line2">
        &copy; All rights reserved by Aoin Designtech Pvt ltd. <b>Ph</b>
        9840509000<b>Mail</b>
        <a href="mailto:vignesh@aoin.in">vignesh@aoin.in</a>
      </p> */}
    </div>
  );
}

export default Footer;
