import React, { Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Loading from "./components/loading/loading";

// Lazy-loaded pages
const Home = lazy(() => import("./components/home/home"));
const Services = lazy(() => import("./components/services/services"));
const ContactUs = lazy(() => import("./components/contactUs/contactUs"));
const AboutUs = lazy(() => import("./components/aboutUs/aboutUs"));
const Projects = lazy(() => import("./components/projects/projects"));
const Project = lazy(() => import("./components/project/project"));

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [delayedLoading, setDelayedLoading] = useState(true); // Keeps loading visible longer
  const LOADINGPAGEDELAYTIME = 6000;

  useEffect(() => {
    setLoading(true); // Show loading screen immediately

    const loadTimer = setTimeout(() => {
      setLoading(false); // Page is loaded
      const delayTimer = setTimeout(() => setDelayedLoading(false), LOADINGPAGEDELAYTIME); // Extra 3 sec delay
      return () => clearTimeout(delayTimer); // Cleanup delay timer
    }, 500); // Simulated page load time

    return () => clearTimeout(loadTimer); // Cleanup on route change
  }, [location.pathname]);

  return (
    <div className="App">
      {!delayedLoading && <Header />}
      <div className={location.pathname === "/" ? "pageContainer pagePadding" : "pageContainer"}>
        <Suspense fallback={<Loading />}>
          {delayedLoading ? <Loading /> : (
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/aboutUs" element={<AboutUs />} />
              <Route path="/services" element={<Services />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/contactUs" element={<ContactUs />} />
              <Route path="/project/:projectid" element={<Project />} />
            </Routes>
          )}
        </Suspense>
      </div>
      {!delayedLoading && <Footer />}
    </div>
  );
}

export default App;
