import React, { useEffect } from "react";
import "./loading.scss";

function Loading() {
  return (
    <div className="loading flex">
      <div className="imgContainer flex">
        <img src="/images/aoinLogoTransparentBackground.png" alt="" />
      </div>
      <h1 className="headingFont">
        Welcome to AOIN DESIGNTECH — Designing the Future, Today.
      </h1>
    </div>
  );
}

export default Loading;
