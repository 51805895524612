import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./header.scss";
interface NavItem {
  name: string;
  path: string;
}
const Header: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const navItems: NavItem[] = [
    { name: "Home", path: "/" },
    { name: "About us", path: "/aboutUs" },
    { name: "Projects", path: "/projects" },
    { name: "Contact us", path: "/contactUs" },
  ];
  return (
    <div className="headerDiv flex">
      <Link to="/" title="Home" className="logoLink">
        <img
          src={`${process.env.PUBLIC_URL}/images/aoinLogoTransparentBackground.png `}
          alt=""
          className="logo"
        />
      </Link>
      <div className="middle flex">
        <div className="navBar flex">
          {navItems.map((navItem, index) => {
            return (
              <>
                <li
                  className={`navItem ${
                    currentPath === navItem.path ? "active" : ""
                  }`}
                >
                  <Link to={navItem.path} title={navItem.name} className="headingFont">
                    {navItem.name}
                  </Link>
                </li>
              </>
            );
          })}
        </div>
      </div>
      <div className="right flex">
          <Link to="https://www.instagram.com/aoin_designtech/" target="_blank" title="Instagram" className="instagram">
            <img src="images/instagram.svg" alt="" />
          </Link>
      </div>
    </div>
  );
};

export default Header;
